
import { defineComponent, PropType, toRefs } from "vue";
import AvatarItem from "./AvatarItem.vue";
import { AvatarItemProps } from "./type";

export default defineComponent({
  name: "AvatarList",
  components: { AvatarItem },
  props: {
    data: {
      type: Array as PropType<AvatarItemProps[]>,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: false,
    },
    callback: {
      type: Function,
      default: () => {},
    },
    showName: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { data, callback } = toRefs(props);
    const openCard = () => {
      window.jme.im.openContactSelector({
        title: "选择加签人",
        selected: data.value,
        callback(res) {
          if (Array.isArray(res)) {
            callback.value(res);
          }
        },
      });
    };
    return { openCard };
  },
});

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "sort-list" }
const _hoisted_2 = { class: "sort-list-collapse-title" }
const _hoisted_3 = { class: "sort-list-collapse-title-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Empty = _resolveComponent("Empty")!
  const _component_van_checkbox = _resolveComponent("van-checkbox")!
  const _component_TaskItem = _resolveComponent("TaskItem")!
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_list = _resolveComponent("van-list")!
  const _component_RecycleScroller = _resolveComponent("RecycleScroller")!
  const _component_van_checkbox_group = _resolveComponent("van-checkbox-group")!
  const _component_van_collapse_item = _resolveComponent("van-collapse-item")!
  const _component_van_collapse = _resolveComponent("van-collapse")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.loading && _ctx.list.length === 0)
      ? (_openBlock(), _createBlock(_component_Empty, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_van_collapse, {
      modelValue: _ctx.activeIds,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeIds) = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (sort, index) => {
          return (_openBlock(), _createBlock(_component_van_collapse_item, {
            key: index,
            name: sort.processDefinitionID
          }, {
            title: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_van_checkbox, {
                  checked: sort.checked,
                  disabled: 
                _ctx.activeIds.indexOf(sort.processDefinitionID) === -1 ||
                (sort.list || []).filter((item) => !item._disabled).length === 0
              ,
                  onTouchend: _withModifiers(() => _ctx.sortTouchEnd(sort), ["stop"]),
                  onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                }, null, 8, ["checked", "disabled", "onTouchend"]),
                _createElementVNode("div", _hoisted_3, _toDisplayString(sort.processDefinitionName) + "（" + _toDisplayString(String(sort.sameProcessCount)) + "） ", 1)
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_van_checkbox_group, {
                modelValue: _ctx.checkedMap[sort.processDefinitionID],
                "onUpdate:modelValue": ($event: any) => ((_ctx.checkedMap[sort.processDefinitionID]) = $event),
                onChange: (names) => _ctx.handleCheckedChange(names, sort)
              }, {
                default: _withCtx(() => [
                  (sort.sameProcessCount < 200)
                    ? (_openBlock(), _createBlock(_component_van_list, {
                        key: 0,
                        offset: "100"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sort.list || [], (item, i) => {
                            return (_openBlock(), _createBlock(_component_van_cell, { key: i }, {
                              value: _withCtx(() => [
                                _createVNode(_component_TaskItem, {
                                  data: item,
                                  showKeyWords: _ctx.showKeyWords
                                }, null, 8, ["data", "showKeyWords"])
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 2
                      }, 1024))
                    : (_openBlock(), _createBlock(_component_RecycleScroller, {
                        key: 1,
                        style: _normalizeStyle(`max-height: ${_ctx.listHeight}px`),
                        "item-size": 68,
                        items: sort.list || [],
                        "key-field": "reqId",
                        onScrollEnd: () => _ctx.onLoadMore(sort)
                      }, {
                        default: _withCtx(({ item }) => [
                          _createVNode(_component_van_cell, null, {
                            value: _withCtx(() => [
                              _createVNode(_component_TaskItem, {
                                data: item,
                                showKeyWords: _ctx.showKeyWords
                              }, null, 8, ["data", "showKeyWords"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["style", "items", "onScrollEnd"]))
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"])
            ]),
            _: 2
          }, 1032, ["name"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
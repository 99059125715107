import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ccbeab3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "avatar-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AvatarItem = _resolveComponent("AvatarItem")!
  const _component_van_icon = _resolveComponent("van-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, i) => {
      return (_openBlock(), _createBlock(_component_AvatarItem, {
        key: i,
        data: item,
        editable: _ctx.editable && _ctx.data.length > 1,
        showName: _ctx.showName,
        onDelete: ($event: any) => (_ctx.$emit('delete', item))
      }, null, 8, ["data", "editable", "showName", "onDelete"]))
    }), 128)),
    (_ctx.editable)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "avatar-list-add",
          onTouchend: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openCard && _ctx.openCard(...args)))
        }, [
          _createVNode(_component_van_icon, { name: "plus" })
        ], 32))
      : _createCommentVNode("", true)
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"font-weight":"600"} }
const _hoisted_2 = { class: "process-center-footer" }
const _hoisted_3 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_4 = { style: {"font-size":"14px","color":"var(--van-gray-7)"} }
const _hoisted_5 = { style: {"display":"none","position":"absolute","z-index":"-1"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_van_search = _resolveComponent("van-search")!
  const _component_u_icon = _resolveComponent("u-icon")!
  const _component_SortList = _resolveComponent("SortList")!
  const _component_van_tab = _resolveComponent("van-tab")!
  const _component_TimeList = _resolveComponent("TimeList")!
  const _component_van_tabs = _resolveComponent("van-tabs")!
  const _component_van_checkbox = _resolveComponent("van-checkbox")!
  const _component_van_button = _resolveComponent("van-button")!
  const _component_ApproveButton = _resolveComponent("ApproveButton")!
  const _component_RejectButton = _resolveComponent("RejectButton")!
  const _component_SearchList = _resolveComponent("SearchList")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "approveRef",
    class: "approve-wrapper",
    style: _normalizeStyle({
      paddingTop: _ctx.paddingTop + 'px',
    })
  }, [
    _createElementVNode("div", {
      class: "approve-wrapper-search",
      style: _normalizeStyle({
        height: _ctx.paddingTop - 44 + 'px',
      })
    }, [
      _createVNode(_component_PageHeader, { title: "我的审批" }),
      _createVNode(_component_van_search, {
        placeholder: "搜索",
        "show-action": "",
        onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSearch = true))
      }, {
        action: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_router_link, { to: "/history" }, {
              default: _withCtx(() => [
                _createTextVNode("审批历史")
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ], 4),
    _createElementVNode("div", {
      class: "approve-wrapper-switch",
      onClick: _cache[1] || (_cache[1] = () => (_ctx.showKeyWords = !_ctx.showKeyWords)),
      style: _normalizeStyle({
        top: _ctx.paddingTop - 44 + 'px',
      })
    }, [
      _createVNode(_component_u_icon, {
        class: "process-action",
        name: !_ctx.showKeyWords ? 'icon-task-list' : 'icon-task-card'
      }, null, 8, ["name"])
    ], 4),
    _createVNode(_component_van_tabs, {
      class: "approve-wrapper-tabs",
      active: _ctx.active,
      "onUpdate:active": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.active) = $event)),
      border: "",
      "line-width": 30,
      onChange: _ctx.handleTabChange,
      style: _normalizeStyle({
        display: 'block',
        height: `calc(100vh - ${_ctx.paddingTop + 50 + 'px'})`,
        overflow: 'overlay',
      })
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_tab, {
          name: "type",
          title: "类别"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SortList, {
              ref: "sortList",
              maxCheckedNum: _ctx.maxCheckedNum,
              showKeyWords: _ctx.showKeyWords,
              onChange: _ctx.handleCheckedChange
            }, null, 8, ["maxCheckedNum", "showKeyWords", "onChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_van_tab, {
          name: "time",
          title: "时间"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_TimeList, {
              ref: "timeList",
              checked: _ctx.checkAll,
              maxCheckedNum: _ctx.maxCheckedNum,
              showKeyWords: _ctx.showKeyWords,
              onChange: _ctx.handleCheckedChange
            }, null, 8, ["checked", "maxCheckedNum", "showKeyWords", "onChange"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["active", "onChange", "style"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.active === 'time')
          ? (_openBlock(), _createBlock(_component_van_checkbox, {
              key: 0,
              style: {"margin-right":"10px"},
              modelValue: _ctx.checkAll,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.checkAll) = $event))
            }, {
              default: _withCtx(() => [
                _createTextVNode("全选")
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_4, "已选择：" + _toDisplayString(_ctx.checkedKeys.length) + "条", 1)
      ]),
      _createVNode(_component_van_button, {
        clstag: "h|keycount|approve_1648190968616|1",
        type: "danger",
        size: "small",
        round: "",
        disabled: _ctx.checkedKeys.length < 1,
        loading: _ctx.loading,
        style: {"padding":"0 26px","font-size":"14px"},
        onClick: _ctx.doApprove
      }, {
        default: _withCtx(() => [
          _createTextVNode("批准")
        ]),
        _: 1
      }, 8, ["disabled", "loading", "onClick"]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_ApproveButton, {
          ref: "approveModal",
          quickApprove: ""
        }, null, 512),
        _createVNode(_component_RejectButton, {
          ref: "rejectModal",
          quickApprove: ""
        }, null, 512)
      ])
    ]),
    (_ctx.showSearch)
      ? (_openBlock(), _createBlock(_component_SearchList, {
          key: 0,
          class: "searchBox",
          showKeyWords: _ctx.showKeyWords,
          onCancel: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showSearch = false)),
          style: _normalizeStyle({
        height: `calc(100vh - ${_ctx.paddingTop - 44 - 54 + 'px'})`,
        top: _ctx.paddingTop - 54 - 44 + 'px',
      })
        }, null, 8, ["showKeyWords", "style"]))
      : _createCommentVNode("", true)
  ], 4))
}
import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, vShow as _vShow, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "proc-search" }
const _hoisted_2 = { action: "" }
const _hoisted_3 = { class: "proc-search-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_search = _resolveComponent("van-search")!
  const _component_Empty = _resolveComponent("Empty")!
  const _component_TaskItem = _resolveComponent("TaskItem")!
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_list = _resolveComponent("van-list")!
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", _hoisted_2, [
      _withDirectives(_createVNode(_component_van_search, {
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        "show-action": "",
        placeholder: "请输入姓名、主题或流水单号",
        onSearch: _ctx.onSearch,
        onCancel: _ctx.onCancel
      }, null, 8, ["modelValue", "onSearch", "onCancel"]), [
        [_directive_focus]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createVNode(_component_Empty, null, null, 512), [
        [_vShow, !_ctx.loading && _ctx.list.length === 0]
      ]),
      _createVNode(_component_van_list, {
        loading: _ctx.loading,
        "onUpdate:loading": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.loading) = $event)),
        finished: _ctx.finished,
        "immediate-check": false,
        offset: "100",
        "finished-text": _ctx.list.length === 0 ? '' : '没有更多了',
        onLoad: _ctx.onLoad,
        "loading-text": "搜索中..."
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
            return (_openBlock(), _createBlock(_component_van_cell, { key: index }, {
              value: _withCtx(() => [
                _createVNode(_component_TaskItem, {
                  data: item,
                  checkable: false,
                  showKeyWords: _ctx.showKeyWords
                }, null, 8, ["data", "showKeyWords"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["loading", "finished", "finished-text", "onLoad"])
    ])
  ]))
}

import { defineComponent, PropType, ref } from "vue";
import { eventBus, taskListClick } from "@/utils";
import { TaskItemProps } from "./../type";
import { Dialog } from "vant";

export default defineComponent({
  name: "TaskItem",
  props: {
    data: {
      type: Object as PropType<TaskItemProps>,
      required: true,
    },
    checkable: {
      type: Boolean,
      default: true,
    },
    showKeyWords: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const flag = ref(1);

    const touchstart = () => {
      flag.value = 1;
    };
    const touchmove = () => {
      flag.value = 0;
    };
    const touchend = (item: TaskItemProps) => {
      if (flag.value === 1) {
        taskListClick(item);
      }
    };

    const handleCheckboxClick = (data: TaskItemProps) => {
      if (data._disabled) {
        Dialog.confirm({
          message: "该审批单需要您到审批详情页审批，请点击“去审批”前往，谢谢！",
          confirmButtonText: "去审批",
        })
          .then(() => {
            taskListClick(data);
          })
          .catch(() => {});
      }
    };

    const beforeClose = (data: TaskItemProps, name: string) => {
      if (data.assigneeStatus === "1") {
        Dialog.alert({
          message: "请等待加签人进行审批",
        }).then(() => {
          // on close
        });
      } else if (data._disabled) {
        Dialog.confirm({
          message: "该审批单需要您到审批详情页审批，请点击“去审批”前往，谢谢！",
          confirmButtonText: "去审批",
        })
          .then(() => {
            taskListClick(data);
          })
          .catch(() => {});
      } else {
        eventBus.emit("openApprove", { data, name });
      }
    };

    return {
      touchstart,
      touchmove,
      touchend,
      handleCheckboxClick,
      beforeClose,
    };
  },
});

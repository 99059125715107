
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  watch,
  computed,
  toRefs,
} from "vue";
import { Toast } from "vant";
import fetchApi from "@/api";
import { store } from "@/utils";
import { SortItemProps, TaskItemProps, CheckedMap } from "./../type";
import TaskItem from "./TaskItem.vue";
import { contextInject } from "@/config/context";
import { RecycleScroller } from "vue-virtual-scroller";

// 搜索栏54 分类tab44 折叠52 底部50
const listHeight = document.body.clientHeight - 54 - 44 - 52 * 2 - 50;

export default defineComponent({
  name: "SortList",
  emits: ["change"],
  components: { TaskItem, RecycleScroller },
  props: {
    maxCheckedNum: {
      type: Number,
      default: 60,
    },
    showKeyWords: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const { maxCheckedNum } = toRefs(props);
    const list = ref<SortItemProps[]>([]);
    const activeIds = ref<string[]>([]);
    const refreshing = ref(false);
    const { loading, updateLoading } = contextInject();
    const checkedMap = reactive<CheckedMap>({});
    const loadNum = 5000;

    const checkedKeys = computed(() =>
      Object.keys(checkedMap).reduce((res, id) => {
        const keys = checkedMap[id];
        return [...res, ...(keys || [])];
      }, [] as unknown as string[])
    );

    watch(activeIds, (val, oldVal) => {
      if (oldVal.length < val.length) {
        const key = val.find((key) => oldVal.indexOf(key) === -1);
        if (key) {
          initList(key);
        }
      }
    });

    watch(
      checkedKeys,
      (val) => {
        const xCheckedKeys = val.filter((key) => key.indexOf("title_") !== 0);
        context.emit("change", xCheckedKeys);
      },
      { deep: true }
    );

    onMounted(() => {
      initData();
    });

    const initData = () => {
      if (refreshing.value) {
        list.value = [];
        clearCheckedKeys();
        refreshing.value = false;
      }
      updateLoading(true);
      return fetchApi
        .getClassAndNum()
        .send()
        .then(async (res) => {
          if (res.data) {
            updateLoading(false);
            const data = JSON.parse(res.data);
            // TODO: 性能测试
            list.value = data;
            if (list.value.length > 0) {
              const key = list.value[0].processDefinitionID;
              activeIds.value.push(key);
              await initList(key);
            }
          }
        })
        .catch(() => {
          updateLoading(false);
        });
    };
    const initList = (key: string, pageNo = 1) => {
      // updateLoading(true);
      return fetchApi
        .getTaskList()
        .data({ processKey: key, pageSize: loadNum, pageNo })
        .send("POST")
        .then((res) => {
          // updateLoading(false);
          const data: { result: TaskItemProps[]; totalCount: number } =
            JSON.parse(res.data);
          // TODO:纠正数目
          console.log(data);
          addList(
            key,
            data.result.map((item) => ({
              ...item,
              _disabled: !item.quickApprove,
            })),
            data.totalCount,
            pageNo
          );
        })
        .catch(() => {
          updateLoading(false);
        });
    };
    const onLoadMore = (sort: SortItemProps) => {
      const {
        list = [],
        sameProcessCount,
        processDefinitionID,
        isFinally,
      } = sort;
      if (!isFinally && list.length < sameProcessCount) {
        const pageNo = list.length / loadNum + 1;
        initList(processDefinitionID, pageNo);
      }
    };
    const onRefresh = () => {
      if (loading.value) {
        refreshing.value = false;
        return;
      }
      refreshing.value = true;
      // 重新加载数据
      activeIds.value = [];
      initData();
    };
    const sortTouchEnd = (sort: SortItemProps) => {
      if (activeIds.value.indexOf(sort.processDefinitionID) === -1) {
        activeIds.value = [...activeIds.value, sort.processDefinitionID];
      } else {
        if (!sort.checked) {
          const enableKeys = (sort.list || [])
            .filter(
              (item) =>
                !item._disabled && checkedKeys.value.indexOf(item.reqId) === -1
            )
            .map((item) => item.reqId);
          if (
            enableKeys.length + checkedKeys.value.length >
            maxCheckedNum.value
          ) {
            Toast.fail(`最多选择${maxCheckedNum.value}条`);
          }
          checkedMap[sort.processDefinitionID] = [
            ...(checkedMap[sort.processDefinitionID] || []),
            ...enableKeys.slice(
              0,
              maxCheckedNum.value - checkedKeys.value.length
            ),
          ];
        } else {
          checkedMap[sort.processDefinitionID] = [];
        }
      }
    };

    const handleCheckedChange = (names: string[], sort: SortItemProps) => {
      let checked = false;
      if (names.length === 0) {
        checked = false;
      } else {
        if (checkedKeys.value.length >= maxCheckedNum.value) {
          checked = true;
        } else {
          checked =
            names.length ===
            (sort.list || []).filter((item) => !item._disabled).length;
        }
      }
      if (checked !== sort.checked) {
        sort.checked = checked;
      }

      if (checkedKeys.value.length > maxCheckedNum.value) {
        Toast.fail(`最多选择${maxCheckedNum.value}条`);
        checkedMap[sort.processDefinitionID].pop();
        return;
      }
    };
    const addList = (
      id: string,
      data: TaskItemProps[],
      total: number,
      pageNo: number
    ) => {
      list.value = list.value
        .map((item) => {
          if (item.processDefinitionID === id) {
            const dataList =
              pageNo === 1 ? data : [...(item.list || []), ...data];
            return {
              ...item,
              list: dataList,
              sameProcessCount: total,
              isFinally: dataList.length === total,
            };
          } else {
            return item;
          }
        })
        .filter((item) => item.sameProcessCount);
    };

    const clearCheckedKeys = () => {
      Object.keys(checkedMap).forEach((key) => {
        checkedMap[key] = [];
      });
    };

    const callback = () => {
      list.value = list.value
        .map((sort) => {
          if (sort.list) {
            let list = [...sort.list];
            let num = sort.sameProcessCount;
            list = list.filter((item) => {
              if (checkedKeys.value.indexOf(item.reqId) === -1) {
                return true;
              } else {
                num--;
              }
            });
            return { ...sort, sameProcessCount: num, list };
          }
          return sort;
        })
        .filter((item) => item.sameProcessCount);
      clearCheckedKeys();
    };

    const getMarkedTaskList = async (reqId: string) => {
      const res = await fetchApi
        .getTaskList()
        .data({
          processInstanceId: reqId,
          pageSize: 1,
          pageNo: 1,
        })
        .silent(true)
        .send("POST");
      if (res && res.data) {
        return JSON.parse(res.data);
      }
      return {};
    };
    const getDelayTask = (
      reqId: string,
      index = 0,
      timeGroup = [0, 500, 1000, 1000]
    ) =>
      new Promise((resolve) => {
        console.log(index);
        setTimeout(async () => {
          const res = await getMarkedTaskList(reqId);
          if ((!res.result || res.result.length > 0) && index < 3) {
            resolve(getDelayTask(reqId, index + 1));
          } else {
            resolve(res);
          }
        }, timeGroup[index]);
      });
    // 处理外置表单审批后跳回刷新列表逻辑
    const afterApprove = async () => {
      const currentTask = store.get("taskItem");
      if (!currentTask) {
        return;
      }
      const { reqId, processKey } = currentTask as unknown as TaskItemProps;
      store.clear("taskItem");
      const res = (await getDelayTask(reqId)) as any;
      if (!res.result) {
        return;
      }
      console.log(res);
      const sortIndex = list.value.findIndex(
        (item) => item.processDefinitionID === processKey
      );
      if (sortIndex === -1) {
        return;
      }
      const sort = list.value[sortIndex];
      const tasks: TaskItemProps[] = sort.list || [];
      let num = sort.sameProcessCount;
      const taskIndex = tasks.findIndex((item) => item.reqId === reqId);
      if (res.result.length > 0) {
        const [node] = res.result;
        tasks.splice(taskIndex, 1, {
          ...node,
          _disabled: !node.quickApprove,
        });
      } else {
        tasks.splice(taskIndex, 1);
        num -= 1;

        const index = checkedKeys.value.indexOf(reqId);
        if (index > -1) checkedKeys.value.splice(index, 1);

        console.log("call jdme setStorage reqId");
        window.jme.storage.setStorage({ key: "reqID", value: "" });
      }
      if (num < 1) {
        list.value.splice(sortIndex, 1);
      } else {
        list.value.splice(sortIndex, 1, {
          ...sort,
          sameProcessCount: num,
          list: tasks,
        });
      }
    };
    const getCheckedNodes = () => {
      const nodes: string[] = [];
      const proxyNodesMap: { [_: string]: string[] } = {};
      Object.keys(checkedMap).forEach((sortKey) => {
        const sort = list.value.find(
          (item) => item.processDefinitionID === sortKey
        );
        if (sort) {
          (sort.list || []).forEach((item) => {
            if (checkedMap[sortKey].indexOf(item.reqId) > -1) {
              if (item.taskType === "proxy") {
                const { assignee, reqId } = item;
                proxyNodesMap[assignee] = [
                  ...(proxyNodesMap[assignee] || []),
                  reqId,
                ];
              } else {
                nodes.push(item.reqId);
              }
            }
          });
        }
      });
      return {
        nodes,
        proxyNodesMap,
      };
    };

    return {
      checkedMap,
      list,
      activeIds,
      refreshing,
      loading,
      checkedKeys,
      listHeight,
      sortTouchEnd,
      onRefresh,
      clearCheckedKeys,
      handleCheckedChange,
      callback,
      afterApprove,
      getCheckedNodes,
      onLoadMore,
    };
  },
});

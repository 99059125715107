
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { Toast } from "vant";
import fetchApi from "@/api";
import { eventBus, getDeviceSomeInfo, store } from "@/utils";
import SortList from "./SortList.vue";
import TimeList from "./TimeList.vue";
import SearchList from "./SearchList.vue";
import { ApproveButton, RejectButton } from "../ApproveDetail/buttons";
import PageHeader from "@/components/PageHeader.vue";
import { TaskItemProps } from "../type";

export default defineComponent({
  name: "MyApprove",
  components: {
    SortList,
    TimeList,
    SearchList,
    ApproveButton,
    RejectButton,
    PageHeader,
  },
  props: {},
  setup() {
    let { safeTop } = getDeviceSomeInfo();
    safeTop = Math.max(safeTop - 46, 0);
    const paddingTop = safeTop + 44 + 54 + 44;
    const active = ref("type");
    const checkAll = ref(false);
    const checkedKeys = ref<string[]>([]);
    const sortList = ref<typeof SortList>();
    const timeList = ref<typeof TimeList>();
    const loading = ref(false);
    const showSearch = ref(false);
    const maxCheckedNum = ref(60);
    // 快捷审批-item
    const approveModal = ref<typeof ApproveButton>();
    const rejectModal = ref<typeof RejectButton>();
    const showKeyWords = ref(true);
    const approveRef = ref<HTMLDivElement>();

    onMounted(() => {
      const tab = approveRef.value?.querySelector(".van-tabs__wrap");
      if (tab) {
        tab.setAttribute("style", `top: ${paddingTop - 44}px`);
      }
      eventBus.on("judgeApprove", () => {
        if (active.value === "type") {
          sortList.value?.afterApprove();
        } else {
          timeList.value?.afterApprove();
        }
      });
      eventBus.on("resetApproveList", () => {
        showSearch.value = false;
        checkAll.value = false;
        checkedKeys.value = [];
        if (active.value === "type") {
          sortList.value?.onRefresh();
        } else {
          timeList.value?.onRefresh();
        }
      });
      eventBus.on("openApprove", (option) => {
        const { data, name } = option as { data: TaskItemProps; name: string };
        store.set("taskItem", data);
        if (name === "approve") {
          approveModal.value?.show(data.reqId);
        }
        if (name === "reject") {
          rejectModal.value?.show(data.reqId);
        }
      });
    });
    onUnmounted(() => {
      eventBus.off("judgeApprove");
      eventBus.off("resetApproveList");
      eventBus.off("openApprove");
    });

    const handleTabChange = () => {
      checkAll.value = false;
      sortList.value?.clearCheckedKeys();
      timeList.value?.clearCheckedKeys();
      store.clear("taskItem");
    };
    const handleCheckedChange = (names: string[]) => {
      checkedKeys.value = names;
    };
    const doApprove = async () => {
      if (checkedKeys.value.length > maxCheckedNum.value) {
        Toast.fail(`目前支持最多审批${maxCheckedNum.value}条数据`);
        return;
      }
      loading.value = true;

      interface TaskCheckedData {
        nodes: string[];
        proxyNodesMap: { [_: string]: string[] };
      }

      const taskCheckedData: TaskCheckedData =
        active.value === "type"
          ? sortList.value?.getCheckedNodes()
          : timeList.value?.getCheckedNodes();

      const { nodes, proxyNodesMap } = taskCheckedData;
      const fetchGroup = [];
      if (nodes.length > 0) {
        fetchGroup.push(
          fetchApi
            .approve()
            .data({
              processInstanceIdList: nodes,
              systemCode: "oa-m",
              submitResult: "1",
            })
            .silent(true)
            .send("POST"),
        );
      }
      if (Object.keys(proxyNodesMap).length > 0) {
        Object.keys(proxyNodesMap).forEach((assignee) => {
          fetchGroup.push(
            fetchApi
              .proxyApprove()
              .data({
                processInstanceIdList: proxyNodesMap[assignee],
                systemCode: "oa-m",
                submitResult: "1",
                assignee,
              })
              .silent(true)
              .send("POST"),
          );
        });
      }
      Promise.all(fetchGroup)
        .then(() => {
          Toast.success("审批成功");
          loading.value = false;
          if (active.value === "type") {
            sortList.value?.callback();
          } else {
            timeList.value?.callback();
          }
        })
        .catch(() => {
          Toast.fail("审批失败");
          loading.value = false;
        });
    };
    return {
      active,
      checkAll,
      checkedKeys,
      sortList,
      timeList,
      approveModal,
      rejectModal,
      loading,
      showSearch,
      maxCheckedNum,
      showKeyWords,
      handleCheckedChange,
      handleTabChange,
      doApprove,
      paddingTop,
      safeTop,
      approveRef,
    };
  },
});

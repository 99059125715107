
import { defineComponent, ref, toRefs, watch } from "vue";
import { Toast } from "vant";
import fetchApi from "@/api";
import { TaskItemProps } from "./../type";
import { store } from "@/utils";
import TaskItem from "./TaskItem.vue";

export default defineComponent({
  name: "TimeList",
  components: { TaskItem },
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    maxCheckedNum: {
      type: Number,
      default: 60,
    },
    showKeyWords: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["change"],
  setup(props, context) {
    const { checked, maxCheckedNum } = toRefs(props);
    const list = ref<TaskItemProps[]>([]);
    const pageIndex = ref(1);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const checkedKeys = ref<string[]>([]);
    const checkboxGroup = ref(null);

    watch(checked, (val) => {
      if (val) {
        // TODO:此处加提示
        checkedKeys.value = [
          ...checkedKeys.value,
          ...list.value
            .filter(
              (item) =>
                !item._disabled && checkedKeys.value.indexOf(item.reqId) === -1
            )
            .map((item) => item.reqId)
            .slice(0, maxCheckedNum.value - checkedKeys.value.length),
        ];
      } else {
        checkedKeys.value = [];
      }
    });

    const onLoad = () => {
      if (refreshing.value) {
        list.value = [];
        checkedKeys.value = [];
        refreshing.value = false;
        pageIndex.value = 1;
      }
      return fetchApi
        .getTaskList()
        .data({ pageNo: pageIndex.value, pageSize: 20 })
        .send("POST")
        .then((res) => {
          if (res.data) {
            const data = JSON.parse(res.data);
            console.log(data);
            list.value = [
              ...list.value,
              ...data.result.map((item: TaskItemProps) => ({
                ...item,
                _disabled: !item.quickApprove,
              })),
            ];
            if (
              data.totalCount <= list.value.length ||
              data.result.length === 0
            ) {
              finished.value = true;
            }
          }
          pageIndex.value++;
          loading.value = false;
        })
        .catch(() => {
          loading.value = false;
          finished.value = true;
        });
    };
    const onRefresh = () => {
      if (loading.value) {
        refreshing.value = false;
        return;
      }
      // 清空列表数据
      finished.value = false;
      refreshing.value = true;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };

    const getCheckedKeys = () => {
      return checkedKeys.value;
    };
    const clearCheckedKeys = () => {
      checkedKeys.value = [];
    };
    const handleCheckedChange = (names: string[]) => {
      if (checkedKeys.value.length > maxCheckedNum.value) {
        Toast.fail(`最多选择${maxCheckedNum.value}条`);
        checkedKeys.value.pop();
        return;
      }
      context.emit("change", names);
    };
    const callback = () => {
      list.value = list.value.filter(
        (item) => checkedKeys.value.indexOf(item.reqId) === -1
      );
      checkedKeys.value = [];
    };

    const getMarkedTaskList = async (reqId: string) => {
      const res = await fetchApi
        .getTaskList()
        .data({
          processInstanceId: reqId,
          pageSize: 1,
          pageNo: 1,
        })
        .silent(true)
        .send("POST");
      if (res && res.data) {
        return JSON.parse(res.data);
      }
      return {};
    };
    const getDelayTask = (
      reqId: string,
      index = 0,
      timeGroup = [0, 500, 1000, 1000]
    ) =>
      new Promise((resolve) => {
        console.log(index);
        setTimeout(async () => {
          const res = await getMarkedTaskList(reqId);
          if ((!res.result || res.result.length > 0) && index < 3) {
            resolve(getDelayTask(reqId, index + 1));
          } else {
            resolve(res);
          }
        }, timeGroup[index]);
      });
    // 处理外置表单审批后跳回刷新列表逻辑
    const afterApprove = async () => {
      const currentTask = store.get("taskItem");
      if (!currentTask) {
        return;
      }
      const { reqId } = currentTask as unknown as TaskItemProps;
      store.clear("taskItem");
      const res = (await getDelayTask(reqId)) as any;
      if (!res.result) {
        return;
      }
      console.log(res);
      const index = list.value.findIndex((item) => item.reqId === reqId);
      if (index > -1) {
        if (res.result && res.result.length > 0) {
          const [node] = res.result;
          list.value.splice(index, 1, {
            ...node,
            _disabled: !node.quickApprove,
          });
        } else {
          list.value.splice(index, 1);
          const i = checkedKeys.value.indexOf(reqId);
          if (i > -1) {
            checkedKeys.value.splice(i, 1);
          }

          console.log("call jdme setStorage reqId");
          window.jme.storage.setStorage({ key: "reqID", value: "" });
        }
      }
    };
    const getCheckedNodes = () => {
      const nodes: string[] = [];
      const proxyNodesMap: { [_: string]: string[] } = {};
      checkedKeys.value.forEach((key) => {
        const node = list.value.find((item) => item.reqId === key);
        if (node) {
          if (node.taskType === "proxy") {
            const { assignee, reqId } = node;
            proxyNodesMap[assignee] = [
              ...(proxyNodesMap[assignee] || []),
              reqId,
            ];
          } else {
            nodes.push(node.reqId);
          }
        }
      });
      return {
        nodes,
        proxyNodesMap,
      };
    };

    return {
      list,
      loading,
      finished,
      refreshing,
      checkedKeys,
      checkboxGroup,
      onLoad,
      onRefresh,
      getCheckedKeys,
      clearCheckedKeys,
      handleCheckedChange,
      callback,
      afterApprove,
      getCheckedNodes,
    };
  },
});

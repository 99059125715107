import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["name"]
const _hoisted_2 = {
  key: 0,
  class: "process-comps-taskItem-checkbox"
}
const _hoisted_3 = { class: "process-comps-taskItem-title" }
const _hoisted_4 = {
  key: 0,
  class: "process-comps-taskItem-tags"
}
const _hoisted_5 = { class: "process-comps-taskItem-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_checkbox = _resolveComponent("van-checkbox")!
  const _component_van_tag = _resolveComponent("van-tag")!
  const _component_van_button = _resolveComponent("van-button")!
  const _component_van_swipe_cell = _resolveComponent("van-swipe-cell")!

  return (_openBlock(), _createBlock(_component_van_swipe_cell, null, {
    right: _withCtx(() => [
      _createVNode(_component_van_button, {
        clstag: "h|keycount|home_1648196201250|7",
        square: "",
        type: "danger",
        text: "驳回",
        style: _normalizeStyle({
          height: '100%',
          opacity: _ctx.data.assigneeStatus === '1' ? '.6' : '1',
        }),
        onClick: _cache[4] || (_cache[4] = () => _ctx.beforeClose(_ctx.data, 'reject'))
      }, null, 8, ["style"]),
      _createVNode(_component_van_button, {
        clstag: "h|keycount|home_1648196201250|6",
        square: "",
        type: "success",
        text: "批准",
        style: _normalizeStyle({
          height: '100%',
          opacity: _ctx.data.assigneeStatus === '1' ? '.6' : '1',
        }),
        onClick: _cache[5] || (_cache[5] = () => _ctx.beforeClose(_ctx.data, 'approve'))
      }, null, 8, ["style"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "process-comps-taskItem",
        name: _ctx.data.reqId
      }, [
        (_ctx.checkable)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_van_checkbox, {
                name: _ctx.data.reqId,
                disabled: _ctx.data._disabled,
                onClick: _cache[0] || (_cache[0] = () => _ctx.handleCheckboxClick(_ctx.data))
              }, null, 8, ["name", "disabled"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          clstag: "h|keycount|approve_1648190968616|2",
          class: "process-comps-taskItem-content",
          onTouchstart: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.touchstart && _ctx.touchstart(...args))),
          onTouchmove: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.touchmove && _ctx.touchmove(...args))),
          onTouchend: _cache[3] || (_cache[3] = () => _ctx.touchend(_ctx.data))
        }, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.data.assigneeStatus === '1')
              ? (_openBlock(), _createBlock(_component_van_tag, {
                  key: 0,
                  plain: "",
                  type: "danger"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("等待加签")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.data.taskType === 'proxy')
              ? (_openBlock(), _createBlock(_component_van_tag, {
                  key: 1,
                  plain: "",
                  type: "danger"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("代理")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.data.taskType === 'addsigner')
              ? (_openBlock(), _createBlock(_component_van_tag, {
                  key: 2,
                  plain: "",
                  type: "danger"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("加签")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.data.reqName), 1)
          ]),
          (_ctx.showKeyWords)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.keywords || [], (item) => {
                  return (_openBlock(), _createBlock(_component_van_tag, {
                    key: item.businessColumnId,
                    color: "#F0F1F2",
                    "text-color": "#9D9D9D"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.businessColumnName) + "：" + _toDisplayString(item.businessColumnValue), 1)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", null, _toDisplayString(_ctx.data.reqUserName), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.data.taskTime), 1)
          ])
        ], 32)
      ], 8, _hoisted_1)
    ]),
    _: 1
  }))
}

import { defineComponent, ref } from "vue";
import fetchApi from "@/api";
import { TaskItemProps } from "./../type";
import TaskItem from "./TaskItem.vue";

export default defineComponent({
  name: "SearchValue",
  components: { TaskItem },
  emits: ["cancel"],
  props: {
    showKeyWords: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const value = ref("");
    const list = ref<TaskItemProps[]>([]);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const pageIndex = ref(1);

    const onLoad = () => {
      if (!value.value) {
        loading.value = false;
        list.value = [];
        return;
      }
      if (refreshing.value) {
        list.value = [];
        refreshing.value = false;
        pageIndex.value = 1;
      }
      loading.value = true;
      return fetchApi
        .getTaskList()
        .data({
          pageNo: pageIndex.value,
          pageSize: 20,
          conditionSearch: value.value,
        })
        .send("POST")
        .then((res) => {
          if (res.data) {
            const data = JSON.parse(res.data);
            list.value = [...list.value, ...data.result];
            if (
              data.totalCount <= list.value.length ||
              data.result.length === 0
            ) {
              finished.value = true;
            }
          }
          pageIndex.value++;
          loading.value = false;
        })
        .catch(() => {
          loading.value = false;
          finished.value = true;
        });
    };
    const onSearch = () => {
      // 清空列表数据
      finished.value = false;
      refreshing.value = true;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };
    const onCancel = () => {
      value.value = "";
      loading.value = false;
      list.value = [];
      context.emit("cancel");
    };

    return {
      value,
      list,
      loading,
      finished,
      onLoad,
      onSearch,
      onCancel,
    };
  },
});
